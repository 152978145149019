import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import { BlogFacade } from '../Post Header/utils/blog.facade';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const facade = new BlogFacade(flowAPI);

  $w('#tagsElement').onClick((event: any) => {
    const tagsClicked = event.target.value;
    const lastTagClicked = tagsClicked.at(-1);
    if (!lastTagClicked) {
      throw new Error('No tag clicked');
    }
    facade.navigateToTagFeed(lastTagClicked);
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setTags: (tags: BlogTag[]) => {
        const formattedTags = tags.map((tag) => ({
          label: tag.label,
          value: tag.slug,
        }));
        $w('#tagsElement').options = formattedTags;
      },
    },
  };
});
